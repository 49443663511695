//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Header, Footer } from '@/components/Layout'

export default {
  components: { Header, Footer },
  data() {
    return {
      metaTitle: this.$t('metaTitle'),
      metaDescription: this.$t('metaDescription'),
      metaKeywords: this.$t('metaKeywords'),
    }
  },

  head() {
    return {
      link: [
        {
          hid: 'icon',
          rel: 'icon',
          type: 'image/x-icon',
          href: `${process.env.webUrl}/${process.env.webName}.ico`,
        },
      ],
      script: [
        {
          innerHTML: 'window.yaContextCb=window.yaContextCb||[]',
          type: 'text/javascript',
          hid: 'yaContextCb-loader',
        },
        {
          src: 'https://yandex.ru/ads/system/context.js',
          async: true,
          hid: 'yandex-ad-script',
        },
      ],
      __dangerouslyDisableSanitizersByTagID: {
        'yaContextCb-loader': ['innerHTML'],
      },
    }
  },

  mounted() {
    window.oncontextmenu = function () {
      return false
    }
    document.onkeydown = function (e) {
      if (e.keyCode === 123) {
        return false
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
        return false
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
        return false
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
        return false
      }
      if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
        return false
      }
    }
    setTimeout(function () {
      window.scrollTo(0, 1)
    }, 1000)

    const intervalId = setInterval(() => {
      if (window.yaContextCb && typeof window.Ya !== 'undefined') {
        window.yaContextCb.push(() => {
          window.Ya.Context.AdvManager.render({
            blockId: 'R-A-8875456-5',
            type: 'topAd',
          })
        })

        window.yaContextCb.push(() => {
          window.Ya.Context.AdvManager.render({
            blockId: 'R-A-8875456-2',
            renderTo: 'yandex_rtb_R-A-8875456-2',
            type: 'feed',
          })
        })

        window.yaContextCb.push(() => {
          window.Ya.Context.AdvManager.render({
            blockId: 'R-A-8875456-3',
            type: 'fullscreen',
            platform: 'touch',
          })
        })

        window.yaContextCb.push(() => {
          window.Ya.Context.AdvManager.render({
            blockId: 'R-A-8875456-4',
            type: 'floorAd',
            platform: 'touch',
          })
        })
        clearInterval(intervalId)
      }
    }, 100)
  },
}
